import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CoreModule } from './core.module';
import { SharedModule } from './shared.module';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LoginComponent } from './components/features/login/login.component';
import { RegistrationModule } from './modules/registration/registration.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  BaseVendorAPI,
  BookmarkAPI,
  CapabilityApi,
  ChatApi,
  ClassificationApi,
  DatadogModule,
  ENVIRONMENT,
  HttpAuthInterceptor,
  IdentityApi,
  InteractionsApi,
  LoggerDatadogService,
  MapboxAPI,
  MapboxService,
  MessagingModule,
  NoteAPI,
  NotifyModule,
  OktaService,
  PaperlessPartsApi,
  PdfDocumentAPI,
  PicturesAPI,
  RecentlyViewedAPI,
  SCLoggerDatadogService,
  ShadowBoxModule,
  USER_ACCOUNT_QUERY,
  UserApplicationApi,
  UserPermissionApi,
  VendorAPI,
  VendorSearchApi,
  VerifierApi
} from 'sustainment-component';
import { ProjectsAPI } from './api/projects.api';
import { PublicOpportunityApi } from './api/public-opportunity.api';
import { LoginContainerComponent } from './containers/login/login.container';
import { BookmarkActions } from './store/bookmark/bookmark.action';
import { BookmarkQuery } from './store/bookmark/bookmark.query';
import { BookmarkStore } from './store/bookmark/bookmark.store';
import { CapabilityActions } from './store/capability/capability.action';
import { CapabilityQuery } from './store/capability/capability.query';
import { ClassificationActions } from './store/classification/classification.action';
import { ClassificationQuery } from './store/classification/classification.query';
import { ConnectionCenterAction } from './store/connection-center/connection-center.action';
import { ConnectionCenterQuery } from './store/connection-center/connection-center.query';
import { DocumentTypeQuery } from './store/documenttype/documenttype.query';
import { MapSettingAction } from './store/map-setting/map-setting.action';
import { MapSettingQuery } from './store/map-setting/map-setting.query';
import { NoteActions } from './store/note/note.action';
import { NoteQuery } from './store/note/note.query';
import { ProjectsActions } from './store/projects/projects.action';
import { ProjectsQuery } from './store/projects/projects.query';
import { RecentlyViewedAction } from './store/recently-viewed/recently-viewed.action';
import { RoleAction } from './store/role/role.action';
import { RoleQuery } from './store/role/role.query';
import { SearchConfigurationQuery } from './store/search-configuration/search-configuration.query';
import { StateQuery } from './store/state/state.query';
import { SubscriptionsActions } from './store/subscriptions/subscriptions.action';
import { SubscriptionsQuery } from './store/subscriptions/subscriptions.query';
import { UserManagementAction } from './store/user-management/user-management.action';
import { UserManagementQuery } from './store/user-management/user-management.query';
import { UserAccountAction } from './store/userAccount/user-account.action';
import { UserAccountQuery } from './store/userAccount/user-account.query';
import { VendorActions } from './store/vendor/vendor.action';
import { VendorQuery } from './store/vendor/vendor.query';
import { VendorSearchActions } from './store/vendorsearch/vendor-search.action';
import { VendorSearchQuery } from './store/vendorsearch/vendor-search.query';
import { PortalOktaService } from './services/okta.service';
import { PublicOpportunitiesQuery } from './store/public-opportunities/public-opportunities.query';
import { AuthService } from './services/auth.service';
import { PartAPI } from './api/part.api';
import { OktaTokenInterceptor } from './api/oktaTokenInterceptor';

@NgModule({
  declarations: [AppComponent, LoginComponent, LoginContainerComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    RegistrationModule,
    ShadowBoxModule,
    ReactiveFormsModule,
    InputMaskModule,
    InputSwitchModule,
    ConfirmDialogModule,
    MessagingModule,
    DatadogModule.forRoot({
      datadogService: {
        provide: SCLoggerDatadogService,
        useExisting: LoggerDatadogService
      }
    }),
    NotifyModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OktaTokenInterceptor,
      multi: true
    },
    { provide: Window, useValue: window },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: 'environment', useValue: environment },
    { provide: 'UserAccountAction', useClass: UserAccountAction },
    { provide: 'UserAccountQuery', useClass: UserAccountQuery },
    { provide: USER_ACCOUNT_QUERY, useClass: UserAccountQuery },
    { provide: 'ConnectionCenterAction', useClass: ConnectionCenterAction },
    { provide: 'ConnectionCenterQuery', useClass: ConnectionCenterQuery },
    { provide: 'VendorSearchActions', useClass: VendorSearchActions },
    { provide: 'VendorSearchQuery', useClass: VendorSearchQuery },
    { provide: 'BookmarkActions', useClass: BookmarkActions },
    { provide: 'BookmarkQuery', useClass: BookmarkQuery },
    { provide: 'NoteActions', useClass: NoteActions },
    { provide: 'NoteQuery', useClass: NoteQuery },
    { provide: 'CapabilityActions', useClass: CapabilityActions },
    { provide: 'CapabilityQuery', useClass: CapabilityQuery },
    { provide: 'MapSettingQuery', useClass: MapSettingQuery },
    { provide: 'MapSettingAction', useClass: MapSettingAction },
    { provide: 'SearchConfigurationQuery', useClass: SearchConfigurationQuery },
    { provide: 'ClassificationActions', useClass: ClassificationActions },
    { provide: 'VendorActions', useClass: VendorActions },
    { provide: 'ClassificationQuery', useClass: ClassificationQuery },
    { provide: 'ProjectsAPI', useClass: ProjectsAPI },
    { provide: 'PartAPI', useClass: PartAPI },
    { provide: 'BookmarkStore', useClass: BookmarkStore },
    {
      provide: 'RecentlyViewedCompaniesAction',
      useClass: RecentlyViewedAction
    },
    { provide: 'VendorQuery', useClass: VendorQuery },
    { provide: 'StateQuery', useClass: StateQuery },
    { provide: 'ProjectsActions', useClass: ProjectsActions },
    { provide: 'ProjectsQuery', useClass: ProjectsQuery },
    { provide: 'PublicOpportunitiesQuery', useClass: PublicOpportunitiesQuery },
    { provide: 'UserManagementAction', useClass: UserManagementAction },
    { provide: 'UserManagementQuery', useClass: UserManagementQuery },
    { provide: 'RoleAction', useClass: RoleAction },
    { provide: 'RoleQuery', useClass: RoleQuery },
    { provide: 'SubscriptionsActions', useClass: SubscriptionsActions },
    { provide: 'SubscriptionsQuery', useClass: SubscriptionsQuery },
    { provide: 'DocumentTypeQuery', useClass: DocumentTypeQuery },
    { provide: OktaService, useClass: PortalOktaService },
    { provide: 'AuthService', useClass: AuthService },
    ConfirmationService,
    MapboxService,
    MapboxAPI,
    NoteAPI,
    BookmarkAPI,
    RecentlyViewedAPI,
    InteractionsApi,
    PicturesAPI,
    CapabilityApi,
    IdentityApi,
    VendorSearchApi,
    ClassificationApi,
    ChatApi,
    BaseVendorAPI,
    PdfDocumentAPI,
    VendorAPI,
    VerifierApi,
    PublicOpportunityApi,
    UserPermissionApi,
    PaperlessPartsApi,
    UserApplicationApi
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
