import {
  IGeocodeFeature,
  IVendor,
  IVendorAddress,
  IVendorDBA,
  IVendorIndustry,
  VendorCapabilityItems
} from 'sustainment-models';
import { IStateData } from '../state/state.model';

export interface IVendorOnboardingSave {
  name: string;
  location: IGeocodeFeature;
  processes: VendorCapabilityItems[];
  industries: VendorCapabilityItems[];
  website: string;
}

export interface IVendorOnboardingResult {
  name: string;
  primaryAddress: {
    address: string;
    postalCode: string;
    state: string;
    latitude: number;
    longitude: number;
    city: string;
    county: string;
    country: string;
  };
  logo: string | null;
  website: string | null;
  processes: unknown[];
  industries: IVendorIndustry[];
  sustainmentId: string;
  isBuyer: boolean;
  isSupplier: boolean;
  isOther: boolean;
}

export interface IVendorLocation extends IVendor {
  location: IGeocodeFeature | null;
  descriptionPlaceholder: string;
  logoLoaded: boolean;
  primaryAddress: string;
  primaryAddressFormated?: string;
  website: string;
}

export const mockVendorDBA: IVendorDBA = {
  dba: '',
  vendorDBAId: 0,
  orderIndex: 0
};

export const mockState: IStateData = {
  stateId: 0,
  name: '',
  abbreviation: ''
};

export const mockVendorAddress: IVendorAddress = {
  vendorAddressId: 0,
  address: '',
  city: '',
  postalCode: '',
  state: mockState,
  isFavorite: false,
  orderIndex: 0,
  unit: '',
  county: ''
};

export const mockVendorLocation: IVendorLocation = {
  location: null,
  logoUrl: null,
  primaryAddress: '',
  descriptionPlaceholder: '',
  connected: false,
  inviteRequested: false,
  wantsToConnect: false,
  connectionRequested: false,
  vendorId: 0,
  name: '',
  logo: null,
  description: '',
  legalName: '',
  dba: '',
  duns: '',
  cage: '',
  ein: '',
  stateIncorporation: '',
  website: '',
  linkedInProfile: '',
  phone: '',
  fax: '',
  email: '',
  status: '',
  employeeCount: '',
  shopSize: '',
  smallBusiness: false,
  addresses: [],
  contacts: [],
  processes: [],
  dbas: [],
  materials: [],
  industries: [],
  certifications: [],
  classifications: [],
  verified: false,
  logoLoaded: false,
  odysseusId: '',
  cover: '',
  coverUrl: '',

  isBuyer: false,
  isSupplier: false,
  isOther: false
};

export interface IVendorDetails {
  employeeCount: string;
  cage: string;
  shopSize: string;
  startedOnUtc: string;
  dbas: IVendorDBA[];
  duns: string;
  stateIncorporation: string;
  legalName: string;
}
