import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import {
  CheckoutResponse,
  Customer,
  CustomerCreated,
  PortalResponse,
  StripeCustomer,
  StripeSubscription
} from 'sustainment-models';

@Injectable()
export class StripeAPI {
  protected urlBase: string;

  public constructor(public _http: HttpClient) {
    this.urlBase = environment.identityApiBaseUrl + 'stripe/';
  }

  public getPlans(): Observable<StripeSubscription[]> {
    return this._http.get<StripeSubscription[]>(this.urlBase + 'products');
  }

  public getSubscriptions(sustainmentId: string): Observable<StripeCustomer> {
    return this._http.get<StripeCustomer>(
      this.urlBase + `customers/${sustainmentId}`
    );
  }

  public createCustomer(customer: Customer): Observable<CustomerCreated> {
    return this._http.post<CustomerCreated>(
      this.urlBase + 'customers/create',
      customer
    );
  }
  public createSubscription(
    sustainmentId: string
  ): Observable<CustomerCreated> {
    return this._http.post<CustomerCreated>(
      this.urlBase + `subscriptions/create/${sustainmentId}`,
      {}
    );
  }

  public createPortal(
    customerId: string,
    body: unknown
  ): Observable<PortalResponse> {
    return this._http.post<PortalResponse>(
      this.urlBase + `${customerId}/portal`,
      body
    );
  }

  public createCheckout(
    sustainmentId: string,
    body: unknown
  ): Observable<CheckoutResponse> {
    return this._http.post<CheckoutResponse>(
      this.urlBase + `${sustainmentId}/checkout`,
      body
    );
  }
}
