import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IdentityApi, NotifyService } from 'sustainment-component';
import { IUser } from 'sustainment-models';
import { IIdentityUser } from '../userAccount/user-account.model';
import { UserAccountQuery } from '../userAccount/user-account.query';
import { ICreateOrEditUserRequest } from './user-management.model';
import { UserManagementStore } from './user-management.store';

@Injectable({ providedIn: 'root' })
export class UserManagementAction {
  public newAccountCreated = new Subject<IUser>();
  public accountUpdated = new Subject<void>();
  public accountDeleted = new Subject<void>();

  private _orgUserMap = new Map<string, IIdentityUser>();

  public constructor(
    private _api: IdentityApi,
    private store: UserManagementStore,
    private _userAccountQuery: UserAccountQuery,
    private _notifyService: NotifyService
  ) {
    this.setSustainmentId();
  }

  public getStateData(
    pageSize?: number,
    pageIndex?: number,
    ignoreLoading = false
  ): void {
    if (!this.store.getValue()?.adminInfo?.sustainmentId) return;

    if (!ignoreLoading) this.store.setLoading(true);

    this._api
      .getOrganizationUsers(
        this.store.getValue()?.adminInfo?.sustainmentId,
        pageSize,
        pageIndex
      )
      .pipe(
        map((results) => {
          const adminUserName = this.store
            .getValue()
            .adminInfo.userName.toString();
          const newResults = results.map((user) => {
            return this.convertIdentityToUser(user, adminUserName);
          });
          return newResults;
        })
      )
      .subscribe((results) => {
        this.store.update({
          users: results
        });
        this.store.setLoading(false);
      });
  }

  public getOrgUsers(): void {
    if (!this.store.getValue().organizationUsers) {
      this.store.setLoading(true);
      const sustainmentId = this._userAccountQuery.sustainmentId || '';
      this._api
        .getOrganizationUsers(sustainmentId)
        .pipe(
          tap((data) => {
            const orgUsers: IIdentityUser[] = [];
            data.forEach((user) => {
              user.name = user.firstName + ' ' + (user?.lastName || '');
              orgUsers.push(user);
              this._orgUserMap.set(user.externalId, user);
            });
            return orgUsers;
          })
        )
        .subscribe({
          next: (organizationUsers) => {
            this.store.update({ organizationUsers });
            this.store.setLoading(false);
          },
          error: () => {
            this.store.update({ organizationUsers: [] });
            this.store.setLoading(false);
          }
        });
    }
  }

  public getOrgUserByExternalId(id: string): IIdentityUser | null {
    return this._orgUserMap.get(id) || null;
  }

  public addUser(user: ICreateOrEditUserRequest): void {
    this.store.setLoading(true);
    const adminUserName = this.store.getValue().adminInfo.userName;

    user.sustainmentId = this.store.getValue().adminInfo.sustainmentId;

    const nameParts = user.name.split(' ');

    // Handle user's name being more than two words (i.e. multi-word last names)
    if (nameParts.length > 2) {
      user.firstName = nameParts[0];
      user.lastName = nameParts.slice(1).join(' ');

      user.initial = `${user.firstName[0]}${user.lastName[0]}`;
    }

    this._api.addOrEditUser(user).subscribe({
      next: (data) => {
        const currentUsers = this.store.getValue().users;
        const userToSave = this.convertIdentityToUser(data, adminUserName);
        if (data.temporaryPassword) this.newAccountCreated.next(userToSave);
        else this.accountUpdated.next();
        this.store.update({ users: [...currentUsers, userToSave] });
        this.store.setLoading(false);
      },
      error: (e: HttpErrorResponse) => {
        this.store.setLoading(false);
        this._notifyService.showDanger(e.error.message);
      }
    });
  }

  public updateRole(user: IUser): void {
    this.store.setLoading(true);

    const organizationUser = this.store
      .getValue()
      .organizationUsers.find((e) => e.email == user.email);

    const dto = {
      roleId: user.role.id,
      userId: organizationUser?.externalId,
      sustainmentId: this.store.getValue().adminInfo.sustainmentId
    };

    this._api.updateUserRole(dto).subscribe(
      () => {
        let users = this.store.getValue().users;
        users = users.map((e) => {
          if (e.email === user.email) {
            return {
              ...e,
              role: user.role
            };
          }
          return e;
        });
        this.store.update({ users });
        this.store.setLoading(false);
      },
      () => {
        this.store.setLoading(false);
      }
    );
  }

  public deleteUser(user: IUser): void {
    this.store.setLoading(true);
    const sustainmentId = this.store.getValue().adminInfo.sustainmentId;
    this._api.deleteUser(user.id, sustainmentId).subscribe(
      () => {
        this.store.update((currentValue) => {
          const updatedUsersList = currentValue.users.filter(
            (c) => c.id !== user.id
          );

          this.accountDeleted.next();

          return { users: updatedUsersList };
        });

        this.store.setLoading(false);
      },
      () => this.store.setLoading(false)
    );
  }

  public acceptUser(user: IUser): void {
    this.store.setLoading(true);
    const sustainmentId = this.store.getValue().adminInfo.sustainmentId;

    this._api.acceptUser(user.id, sustainmentId).subscribe(
      () => {
        this.store.update((currentValue) => {
          const userIndex = currentValue.users.findIndex(
            (c) => c.id === user.id
          );

          const oldUser = { ...currentValue.users[userIndex] } as IUser & {
            pending: boolean;
          };

          oldUser.pending = false;

          const newArray = [
            ...currentValue.users.slice(0, userIndex),
            oldUser,
            ...currentValue.users.slice(userIndex + 1)
          ];

          return { users: newArray };
        });
        this.store.setLoading(false);
      },
      () => this.store.setLoading(false)
    );
  }

  private setSustainmentId(): void {
    this._userAccountQuery.userSession$.subscribe((user) => {
      if (user?.userName && user.sustainmentId)
        this.store.update({
          adminInfo: {
            sustainmentId: user.sustainmentId,
            userName: user.userName
          }
        });
    });
  }

  private convertIdentityToUser(
    identity: IIdentityUser,
    adminUserName: string
  ): IUser {
    return {
      ...identity,
      name: `${identity.firstName} ${identity.lastName}`,
      initial: `${identity.firstName.charAt(0)}${identity.lastName.charAt(0)}`,
      status: identity.lastLogin ? '' : 'pending',
      role: { title: identity.role.description, id: identity.role.roleId },
      isMe: identity.userName === adminUserName,
      permissions: identity.permissions
    };
  }
}
