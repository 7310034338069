import {
  Component,
  OnInit,
  NgZone,
  Inject,
  effect,
  signal
} from '@angular/core';
import { Router } from '@angular/router';
import { akitaDevtools } from '@datorama/akita';
import { environment } from '../environments/environment';
import { Platform } from '@angular/cdk/platform';
import {
  NotifyService,
  WINDOW,
  injectFlag,
  userGuidingIdentityTracking
} from 'sustainment-component';
import { UserAccountQuery } from './store/userAccount/user-account.query';
import { Subject, Subscription, forkJoin, timer } from 'rxjs';
import { VendorQuery } from './store/vendor/vendor.query';
import { UserAccount, UserSession, VendorProfile } from 'sustainment-models';
import { datadogRum } from '@datadog/browser-rum';
import packageJson from '../../package.json';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { LaunchDarklyService } from 'sustainment-component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'Supplier Profile';

  public isRoutingToLogin = false;

  public showLoginDropdown = false;

  public browserNotSupported = false;

  private _userAccount = signal<UserAccount | null>(null);
  private _userSubscription: Subscription;
  private _vendorSubscription: Subscription;
  private _refreshTimerSubscription: Subscription;
  private _user$: Subject<UserSession> = new Subject<UserSession>();
  private _vendor$: Subject<VendorProfile> = new Subject<VendorProfile>();

  private _isAmplitudeSessionReplayOn = injectFlag('amplitudeSessionReplays');

  public constructor(
    public router: Router,
    @Inject(WINDOW) private window: Window,
    private ngZone: NgZone,
    public _platform: Platform,
    private _userAccountQuery: UserAccountQuery,
    private _vendorQuery: VendorQuery,
    public authService: AuthService,
    private _confirmationService: ConfirmationService,
    private _notifyService: NotifyService,
    private _ldService: LaunchDarklyService
  ) {
    if (!environment.production) {
      akitaDevtools(ngZone);
      this.printComponentsLibraryVersion();
    }

    effect(() => {
      if (this._isAmplitudeSessionReplayOn() && this._userAccount()) {
        this.addAmplitudeSessionReplay(this._userAccount()!);
      }
    });
  }

  public ngOnInit(): void {
    this.browserNotSupported =
      !this._platform.BLINK &&
      !this._platform.EDGE &&
      !this._platform.FIREFOX &&
      !this._platform.SAFARI;

    this._userAccountQuery.userAccount$.subscribe((userAccount) => {
      if (environment.production) {
        this.addPendo(userAccount);
        this.addDatadogRum(userAccount);
      }

      this._userAccount.set(userAccount);
      userAccount && this._ldService.initialize(userAccount);
    });

    if (environment.production) {
      this.addAnalyticsTracking();
      this.addGoogleTagManager();
      this.addHubspot();
    }

    // debounce method from Rik Schennink:
    // https://pqina.nl/blog/applying-styles-based-on-the-user-scroll-position-with-smart-css/
    const debounce = (
      fn: (...args: unknown[]) => unknown
    ): EventListenerOrEventListenerObject => {
      let frame: number;

      return (...params: unknown[]): void => {
        if (frame) cancelAnimationFrame(frame);

        frame = requestAnimationFrame(() => {
          fn(...params);
        });
      };
    };

    const storeScroll = (): void => {
      document.documentElement.dataset.scroll = window.scrollY.toString();
    };

    document.addEventListener('scroll', debounce(storeScroll), {
      passive: true
    });

    storeScroll();

    this.addUserGuidingIdentity();

    this.authService.tokenExpiringSoon$.subscribe((isExpiring: boolean) => {
      if (isExpiring) {
        this.timeoutWarning();

        this._refreshTimerSubscription = timer(
          this.authService.timeoutWarning
        ).subscribe(() => {
          this.authService.logout();
        });
      }
    });
  }

  public isLoginPage(): boolean {
    return this.router.url.includes('/login');
  }

  private addAnalyticsTracking(): void {
    const googleTagScript = document.createElement('script');
    googleTagScript.async = true;
    googleTagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.portalGAToken}`;

    const googleDataLayerScript = document.createElement('script');
    googleDataLayerScript.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "${environment.portalGAToken}", { send_page_view: false });`;

    document.head.append(googleTagScript, googleDataLayerScript);
  }

  private addGoogleTagManager(): void {
    const gtagScriptHead = document.createElement('script');

    gtagScriptHead.innerHTML = `(function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-M5J24PQ");`;

    document.head.insertBefore(gtagScriptHead, document.head.firstChild);

    const gtagNoScriptBody = document.createElement('noscript');

    const gtagIframe = document.createElement('iframe');

    gtagIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-M5J24PQ';
    gtagIframe.height = '0';
    gtagIframe.width = '0';
    gtagIframe.style.display = 'none';
    gtagIframe.style.visibility = 'hidden';

    gtagNoScriptBody.append(gtagIframe);

    document.body.insertBefore(gtagNoScriptBody, document.body.firstChild);
  }

  private addPendo(userAccount: UserAccount): void {
    if (!userAccount) return;

    const pendoScriptHead = document.createElement('script');

    pendoScriptHead.innerHTML = `(function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

            pendo.initialize({
                visitor: {
                    id: '${userAccount.externalId}',
                    email: '${userAccount.email}',
                    userId: '${userAccount.externalId}',
                    sustainmentId: '${userAccount.organizations[0].sustainmentId}'
                },
                account: {
                    id: '${userAccount.organizations[0].sustainmentId}',
                    name: '${userAccount.organizations[0].name}',
                    sustainmentId: '${userAccount.organizations[0].sustainmentId}'
                }
            });
    })('ac465e69-b0c3-4777-5844-c545654d2acc');`;

    document.head.appendChild(pendoScriptHead);
  }

  private addHubspot(): void {
    const hubspotScript = document.createElement('script');
    hubspotScript.id = 'hs-script-loader';
    hubspotScript.type = 'text/javascript';
    hubspotScript.defer = true;
    hubspotScript.async = true;
    hubspotScript.src = '//js.hs-scripts.com/20728537.js';

    document.body.appendChild(hubspotScript);
  }

  private addDatadogRum(userAccount: UserAccount): void {
    if (!userAccount) return;

    datadogRum.init({
      applicationId: environment.datadogRum.applicationId,
      clientToken: environment.datadogRum.clientToken,
      site: 'datadoghq.com',
      service: 'Portal-prod',
      env: 'prod',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      version: packageJson.version
    });

    datadogRum.setUser({
      sustainmentId: userAccount.organizations[0].sustainmentId,
      id: userAccount.externalId,
      name: `${userAccount.firstName} ${userAccount.lastName}`,
      email: userAccount.email,
      organizationName: userAccount.organizations[0].name
    });
  }

  private addUserGuidingIdentity = (): void => {
    forkJoin([this._user$, this._vendor$]).subscribe(([user, vendor]) => {
      const properties: Record<string, string | boolean | number>[] = [
        { email: user.userName },
        {
          name: [user.firstName, user.lastName]
            .filter((x) => x.trim().length > 0)
            .join(' ')
        },
        { firstName: user.firstName },
        { lastName: user.lastName },
        { userAccountId: user.userAccountId },
        { features: user.features.map((f) => f.key).join(', ') },
        { isBuyer: vendor.isBuyer },
        { isSupplier: vendor.isSupplier },
        { isOther: vendor.isOther },
        { isPortalAdmin: this._userAccountQuery.isAdmin },
        { environment: environment.environmentName }
      ];
      userGuidingIdentityTracking(user.sustainmentId, properties);
    });

    this.obserUserData();
    this.observeVendorData();
  };

  private obserUserData(): void {
    this._userSubscription = this._userAccountQuery.userSession$.subscribe(
      (user) => {
        if (user) {
          this._user$.next(user);
          this._user$.complete();
          this._userSubscription.unsubscribe();
        }
      }
    );
  }

  private observeVendorData(): void {
    this._vendorSubscription = this._vendorQuery.vendorInfo$.subscribe(
      (vendor) => {
        if (vendor && vendor.sustainmentId) {
          this._vendor$.next(vendor);
          this._vendor$.complete();
          this._vendorSubscription.unsubscribe();
        }
      }
    );
  }

  private timeoutWarning(): void {
    this._confirmationService.confirm({
      key: 'timoutDialog',
      header: 'Session timeout warning',
      message: `You will be logged out in ${
        this.authService.timeoutWarning / (60 * 1000)
      } minutes due to inactivity.`,
      acceptLabel: 'Continue session',
      rejectLabel: 'Logout',
      accept: () => {
        if (this._refreshTimerSubscription) {
          this._refreshTimerSubscription.unsubscribe();
        }
        this.authService.restoreSession().then((successfulLogin: boolean) => {
          if (successfulLogin) {
            this.authService.updateLastActivity();
          } else {
            this._notifyService.showDanger('Error refreshing session');
          }
        });
      },
      reject: () => {
        this.authService.logout();
      }
    });
  }

  private addAmplitudeSessionReplay(userAccount: UserAccount): void {
    const amplitudeScript = document.createElement('script');
    amplitudeScript.src =
      'https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz';
    document.head.appendChild(amplitudeScript);

    const sessionReplayScript = document.createElement('script');
    sessionReplayScript.src =
      'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.6.22-min.js.gz';
    document.head.appendChild(sessionReplayScript);

    amplitudeScript.onload = (): void => {
      sessionReplayScript.onload = (): void => {
        window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
        window.amplitude.init(
          environment.amplitudeApiKey,
          userAccount.externalId
        );
      };
    };
  }

  private printComponentsLibraryVersion(): void {
    /* eslint-disable no-console */
    console.log(
      `Components Library is using version: ${packageJson.dependencies['sustainment-component']}`
    );
    /* eslint-enable no-console */
  }
}

declare const window: {
  amplitude: Amplitude;
  sessionReplay: {
    plugin: (opts: { sampleRate: number }) => void;
  };
} & Window;

interface Amplitude {
  add: (sessionReplay: void) => void;
  init: (apiKey: string, userId: string) => void;
}
